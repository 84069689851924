// loaded on all pages in dashboard and portal admin
var makersApp = {};

makersApp.copyToEmbedClipboard = function(text, url){
  var dummy = document.createElement('input');
  var out ='<script type="module" src="https://ajax.googleapis.com/ajax/libs/model-viewer/4.0.0/model-viewer.min.js"></script>'
  out += '<div style="background-color:#fff; width:400px; height: 300px; font-family:arial;text-align:center">'
  out += '<model-viewer style="width:100%; height:100%" '
  out += 'src="' + text + '" alt="A 3D model of an astronaut" auto-rotate camera-controls></model-viewer>'
  out += '<a href="' + url + '">Made in Makers Empire. See design</a></div>';
  
  document.body.appendChild(dummy);
  dummy.value = out;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
}

makersApp.copyToClipboard = function(text) {
  var dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

makersApp.lessonPlanEmail = function(input) {
  $('#mce-EMAIL').val($('#'+input).val());
}

makersApp.clearForm = function(){
  $("input, textarea").val("");
}

makersApp.validateEmail = function(email) {
  var re = /^.+@.+\..+$/;
  return re.test(email);
} 

makersApp.truncate = function(str, n){
  return (str.length > n) ? str.substr(0, n - 1) + '&hellip;' : str;
};

// make it globally accessible
window.makersApp = makersApp;
